import timbre from '@/assets/timbre.mp3'
export default {
	methods: {
		listenChannels() {
            this.Echo.channel('orders')
            .listen('.orderUpdated', (e) => {
                setTimeout(() => {
                    console.log(e)
                    this.sonido_order(e.order)
                    this.$store.commit('order/add', e.order)
                }, 2000)
            })
            this.Echo.channel('products')
            .listen('.productUpdated', (e) => {
                console.log(e)
                this.$store.commit('product/add', e.product)
            })
            console.log('escuchando canales')
        },
        sonido_order(order) {
            console.log('sonido_order')
            console.log('order models')
            console.log(this.$store.state.order.models)
            let added_order = this.$store.state.order.models.find(_order => {
                return _order.id == order.id
            })
            console.log('added_order')
            console.log(added_order)
            if (typeof added_order == 'undefined') {
                if (order.sonido) {
                    var audio = new Audio(timbre);

                    let intervalo_window = window.setInterval(() => {
                        console.log('enviando audio')
                        audio.play()
                    }, 5000)

                    let intervalo = {
                        intervalo: intervalo_window,
                        order_id: order.id,
                    }

                    this.$store.commit('order/addIntervaloTimbre', intervalo)

                }
            }
        }
	}
}