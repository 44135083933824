<template>
	<b-row
	v-if="show"
	class="footer">
		<b-col
		cols="12"
		md="3"
		lg="2">
			<nav-component></nav-component>
		</b-col>
		<b-col
		cols="12"
		md="3"
		lg="2">
			<social-links></social-links>
		</b-col>
		<b-col
		cols="12"
		md="3"
		lg="3">
			<contact></contact>
		</b-col>
		<b-col
		cols="12"
		md="3"
		lg="2">
			<security></security>
		</b-col>
		<copyright></copyright>	
	</b-row>
</template>
<script>
import NavComponent from '@/components/common/footer/Nav'
import SocialLinks from '@/components/common/footer/SocialLinks'
import Contact from '@/components/common/footer/Contact'
import Security from '@/components/common/footer/Security'
import Copyright from '@/components/common/footer/Copyright'
export default {
	components: {
		NavComponent,	
		SocialLinks,
		Contact,
		Security,
		Copyright,
	},
	computed: {
		show() {
			return !this.authenticated && this.route_name == 'home' 
		}
	}
}
</script>
<style lang="sass">
@import '@/sass/_custom'
.footer 
	background: $blue 
	[class^='col']
		background: $blue !important
		@media screen and (max-width: 768px) 
			margin-bottom: 1em
		.title 
			font-size: 1em
			color: #FFF
			text-align: left !important		
		font-weight: 600
	ul 	
		list-style: none
		padding: 0
		li
			text-align: left
			color: rgba(255,255,255,.8) !important
			a
				color: rgba(255,255,255,.8) !important

</style>