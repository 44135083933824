export default {
	properties: [
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			required: true,
			is_title: true,
		},
		{
			text: 'Color',
			key: 'color_id',
			type: 'select',
			value: 0,
			show: true,
		},
	],
	singular_model_name_spanish: 'Tipo de pedido',
	plural_model_name_spanish: 'Tipos de pedido',
	create_model_name_spanish: 'Nuevo',
	text_delete: 'el',
}