import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth' 
import users from '@/store/users' 
import category from '@/store/category' 
import product from '@/store/product' 
import orders from '@/store/orders/index' 
import cart_product from '@/store/cart_product' 
import order from '@/store/order' 
import employee from '@/store/employee' 
import permission from '@/store/permission' 
import payment_method from '@/store/payment_method' 
import order_type from '@/store/order_type' 
import order_status from '@/store/order_status' 
import color from '@/store/color' 
import buyer_product from '@/store/buyer/product' 
import salon from '@/store/salon' 
import mesa from '@/store/mesa' 

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth, 
        users, 
        category, 
        product, 
        orders, 
        cart_product, 
        order, 
        employee, 
        permission, 
        payment_method,
        order_type,
        order_status,
        color,
        buyer_product,
        salon,
        mesa,
    }
})
