export default {
	methods: {
		image(model, from_model = false) {
			let url 
			let image_url = null
			if (!from_model) {
				if (model.image_url) {
					image_url = model.image_url
				} else if (model.images && model.images[0]) {
					image_url = model.images[0].image_url 
				}
			} else {
				image_url = model[from_model].image_url
			}
			if (this.is_local) {
				image_url = image_url.replace('/public', '')
			}
			return image_url 
		},
		imageUrl(model = null, cropped = false, from_cloudinary = false) {
			if (model) {
				if (this.from_cloudinary) {
					if (cropped) {
						return `https://res.cloudinary.com/lucas-cn/image/upload/c_crop,g_custom/${model}`
					} else {
						return `https://res.cloudinary.com/lucas-cn/image/upload/${model}`
					}
				} else {
					if (!this.is_local) {
						return this.getProductionUrl(model.image_url)
					} 
					return model.image_url
				}
			}
			return null
		},
		
	},
}