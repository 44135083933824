import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.baseURL = 'https://micovid.online'
// axios.defaults.baseURL = 'http://localhost:8000'
export default {
	namespaced: true,
	state: {
		authenticated: null,
		user: null,
		loading: false,
		message: '',
	},
	mutations: {
		setAuthenticated(state, value) {
			state.authenticated = value
		},
		setUser(state, value) {
			state.user = value
		},
		add(state, value) {
			let index = state.models.findIndex(item => {
				return item.id == value.id
			})
			if (index == -1) {
				state.models.unshift(value)
			} else {
				state.models.splice(index, 1, value)
			}
		},
		setLoading(state, value) {
			state.loading = value
		},
		setMessage(state, value) {
			state.message = value
		},
	},
	actions: {
		me({commit}) {
			commit('setLoading', true)
			return axios.get('/api/user')
			.then(res => {
				console.log(res.data.user)
				commit('setLoading', false)
				commit('setAuthenticated', true)
				commit('setUser', res.data.user)
			})
			.catch(() => {
				commit('setLoading', false)
				commit('setAuthenticated', false)
				commit('setUser', null)
			})
		},
		logout({ commit }) {
			commit('setLoading', true)
			return axios.post('/logout')
            .then(() => {
                commit('setLoading', false)
                commit('setAuthenticated', false)
                commit('setUser', null)
            })
            .catch(err => {
                console.log(err)
            })
		},
		deleteImage({ commit, state }) {
			return axios.delete(`/api/delete-current-image/user/${state.user.id}`)
			.then((res) => {
				commit('setUser', res.data.model)
			})
			.catch((err) => {
				console.log(err)
			})
		},
	},
	modules: {
	}
}
