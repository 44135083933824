export default {
	methods: {
		setOrderFromCookies() {
			this.$cookies.keys().forEach(key => {
				if (key.includes('product_')) {
					this.$store.commit('order/addProduct', this.$cookies.get(key))
				}
			})
		},
		clearOrderCookies() {
			this.$cookies.keys().forEach(key => {
				if (key.includes('product_')) {
					this.$cookies.remove(key)
				}
			})
		},
		removeProductFromCookies(product) {
			this.$cookies.keys().forEach(key => {
				if (key.includes('product_'+product.id)) {
					this.$cookies.remove(key)
				}
			})
		},
	}
}