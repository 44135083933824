export default {
	properties: [
		{
			text: 'Nombre',
			key: 'nombre',
			type: 'text',
			show: true,
		},
		{
			text: 'Filas',
			key: 'filas',
			type: 'number',
			show: true,
		},
		{
			text: 'Columnas',
			key: 'columnas',
			type: 'number',
			show: true,
		},
		{
			text: 'Mapa',
			key: 'mapa',
			input_full_width: true,
		},
		{
			text: 'Mesas',
			key: 'mesas',
			not_show_on_form: true,
			has_many: {
				text: 'Mesas',
				model_name: 'mesa',
			},
			not_show: true,
		},
		// {
		// 	text: 'Mapa',
		// 	button: {
		// 		button_text: 'Mapa',
		// 		variant: 'primary',
		// 	},
		// 	modal: 'salon-mapa',
		// 	// type: 'button',
		// },
	],
	singular_model_name_spanish: 'Salon',
	plural_model_name_spanish: 'Salones',
	create_model_name_spanish: 'Nuevo Salon',
	text_delete: 'el',
}