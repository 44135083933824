<template>
	<div>
		<p class="title">
			CONTACTANOS
		</p>
        <ul>
        	<li>
        		<i class="icon-phone"></i>
        		{{ admin.phone }}
        	</li>
        	<li>
        		<i class="icon-mail"></i>
        		{{ admin.email }}
        	</li>
        	<li>
        		<i class="icon-marker"></i>
        		{{ admin.address }} 
        	</li>
        </ul>
	</div>
</template>
<script>
import SocialLinks from '@/components/nav/SocialLinks'
export default {
	components: {
		SocialLinks
	}
}
</script>
<style lang="sass">
.footer
	.social-links
		flex-direction: row
		justify-content: flex-start !important
</style>