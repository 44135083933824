<template>
	<div>
		<p class="title">
			NAVEGACION
		</p>
        <nav-items></nav-items>
	</div>
</template>
<script>
export default {
	components: {
		NavItems: () => import('@/common-vue/components/nav/NavItems')
	}
}
</script>