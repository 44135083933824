import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // Admin
    {
        path: '/login',
        name: 'login',
        component: () => import('@/common-vue/views/Login')
    },
    {
        path: '/recuperar-clave/:view?/:sub_view?',
        name: 'passwordReset', 
        component: () => import('@/common-vue/views/PasswordReset')
    },
    {
        path: '/admin/pedidos/:view?',
        name: 'order',
        component: () => import('@/views/Order')
    },
    {
        path: '/admin/categorias',
        name: 'category',
        component: () => import('@/views/Category')
    },
    {
        path: '/admin/productos/:view?',
        name: 'product',
        component: () => import('@/views/Product')
    },
    {
        path: '/admin/empleados/:view?',
        name: 'employee',
        component: () => import('@/views/Employee')
    },
    {
        path: '/admin/configuracion/:view?',
        name: 'configuration',
        component: () => import('@/common-vue/views/Configuration')
    },
    {
        path: '/admin/abm/:view?',
        name: 'abm',
        component: () => import('@/views/Abm')
    },
    {
        path: '/admin/salones/:view?',
        name: 'salon',
        component: () => import('@/views/Salon')
    },


    // Inicio
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/buyer/Home')
    },
    {
        path: '/productos',
        name: 'buyerProducts',
        component: () => import('@/views/buyer/Products')
    },
    {
        path: '/producto/:view?',
        name: 'buyerProduct',
        component: () => import('@/views/buyer/Product')
    },
    {
        path: '/pedido',
        name: 'buyerOrder',
        component: () => import('@/views/buyer/Order')
    },
    {
        path: '/confirmar-pedido/:view',
        name: 'confirmOrder',
        component: () => import('@/views/buyer/ConfirmOrder')
    },
    {
        path: '/gracias-por-tu-compra',
        name: 'Thanks',
        component: () => import('@/views/buyer/Thanks')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
