import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

import orders_mixin from '@/mixins/orders'

import model from '@/models/order'
export default {
	namespaced: true,
	state: {
		model: {},

		products: [],
		
		total: 0,
	},
	mutations: {
		setModel(state, value = null) {
			if (value) {
				state.model = value
			} else {
				let obj = {
					id: null
				}
				model.props.forEach(prop => {
					obj[prop.key] = prop.value 
				})
				state.model = obj
			}
		},
		setProducts(state, value) {
			state.products = value
		},
		addProduct(state, value) {
			state.products.unshift(value)
		},
		updateProduct(state, value) {
			let index = state.products.findIndex(item => {
				return item.id == value.id
			})
			state.products.splice(index, 1, value)
		},
		removeProduct(state, value) {
			let index = state.products.findIndex(item => {
				return item.id == value.id
			})
			state.products.splice(index, 1)
		},
		setTotal(state, value = null) {
			if (value) {
				state.total = value
			} else {
				state.total = orders_mixin.methods.getTotalProducts(state.products, state.model.discount, false, false)
			}
		},
	},
}
