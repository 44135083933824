<template>
	<nav-component
	:routes="routes">

		<template #right_dropdown>

			<search-btn></search-btn>
			<social-links></social-links>	

		</template>

	</nav-component>		
</template>
<script>
import nav from '@/mixins/nav'
export default {
	mixins: [nav],
	components: {
		NavComponent: () => import('@/common-vue/components/nav/Index'),
		SearchBtn: () => import('@/components/nav/SearchBtn'),
		SocialLinks: () => import('@/components/nav/SocialLinks'),
	},
	computed: {
		routes() {
			if (this.authenticated) {
				return this.auth_routes
			} else {
				return this.unauthenticated_routes
			}
		}
	}
}
</script>