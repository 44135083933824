export default {
	properties: [
		{
			text: 'Imagenes',
			key: 'images',
			type: 'images',
			value: '',
			show: true,
		},
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			required: true,
			is_title: true,
		},
		{
			text: 'Descripcion',
			key: 'description',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'Precio',
			key: 'price',
			type: 'number',
			value: '',
			required: true,
			show: true,
		},
		{
			text: 'Stock',
			key: 'stock',
			type: 'number',
			value: '',
			show: true,
		},
		{
			text: 'Categoria',
			key: 'category_id',
			type: 'select',
			value: 0,
			options_store: 'categories',
			show: true,
		},
		{
			text: 'Disponible en la tienda',
			key: 'online_available',
			type: 'checkbox',
			value: 1,
			unchecked_value: 0,
			show: true,
		},
		{
			text: 'Destacado',
			key: 'featured',
			type: 'checkbox',
			value: 1,
			unchecked_value: 0,
			show: true,
		},
	],
	singular_model_name_spanish: 'Producto',
	plural_model_name_spanish: 'Productos',
	create_model_name_spanish: 'Nuevo producto',
	text_delete: 'el',
}