import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


/*
|--------------------------------------------------------------------------
| AXIOS
|--------------------------------------------------------------------------
*/
import VueAxios from 'vue-axios'
import axios from 'axios'
Vue.use(VueAxios, axios)
Vue.use({
  install (Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL : process.env.VUE_APP_API_URL,
      withCredentials : true
    })
  }
})
Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL : process.env.VUE_APP_API_URL+'/api',
      withCredentials : true
    })
  }
})

/*
|--------------------------------------------------------------------------
| NOTIFICATIONS
|--------------------------------------------------------------------------
*/
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast, {
  position: 'bottom'
})

/*
|--------------------------------------------------------------------------
| SCROLL
|--------------------------------------------------------------------------
*/
Vue.prototype.$scrollToTop = (() => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, 100)
}) 

/*
|--------------------------------------------------------------------------
| BOOTSTRAP
|--------------------------------------------------------------------------
*/
import { BootstrapVue } from 'bootstrap-vue'
import '@/sass/_custom.scss'
Vue.use(BootstrapVue)


/*
|--------------------------------------------------------------------------
| LARALVE-ECHO
|--------------------------------------------------------------------------
*/
window.Pusher = require('pusher-js')
import Echo from "laravel-echo"

Vue.prototype.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'cc1ba97852d976eba33c',
    cluster: 'sa1',
    forceTLS: false
});

/*
|--------------------------------------------------------------------------
| COOKIES
|--------------------------------------------------------------------------
*/
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expire: '7d'})

/*
|--------------------------------------------------------------------------
| MIXINS
|--------------------------------------------------------------------------
*/
import common_generals from './common-vue/mixins/generals'
Vue.mixin(common_generals)
import model_functions from './common-vue/mixins/model_functions'
Vue.mixin(model_functions)
import generals from './mixins/generals'
Vue.mixin(generals)
import images from './common-vue/mixins/images'
Vue.mixin(images)
import permissions from './common-vue/mixins/permissions'
Vue.mixin(permissions)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
