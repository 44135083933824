export default {
	computed: {
		auth_routes() {
			let items = [
				{
					model_name: 'product',
				},
				{
					model_name: 'order',
					params: {
						view: 'nuevo-pedido'
					} 
				},
				{
					model_name: 'category',
				},
				{
					model_name: 'salon',
				},
			]
			if (this.is_owner) {
				items.push({
					model_name: 'employee',
				})
				items.push({
					text: 'AMB',
					name: 'abm', 
					params: {
						view: 'estados-de-pedido'
					}
				})
			}
			return items
		},
		unauthenticated_routes() {
			return [
				{
					text: 'Inicio',
					name: 'home',
				},
				{
					text: 'Productos',
					name: 'buyerProducts',
				},
			]
		},
	},
}