<template>
	<div>
		<p class="title">
			REDES SOCIALES
		</p>
        <social-links></social-links>
	</div>
</template>
<script>
import SocialLinks from '@/components/nav/SocialLinks'
export default {
	components: {
		SocialLinks
	}
}
</script>
<style lang="sass">
.footer
	.social-links
		display: flex
		flex-direction: row
		justify-content: flex-start !important
</style>