export default {
	properties: [
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			required: true,
			show: true,
		},
	],
	singular_model_name_spanish: 'Mesa',
	plural_model_name_spanish: 'Mesas',
	create_model_name_spanish: 'Nueva Mesa',
	text_delete: 'la',
}