import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
export default {
	namespaced: true,
	state: {
		model: null,
		amount: 1,
		loading: false,
	},
	mutations: {
		setModel(state, value) {
			state.model = value
		},
		setAmount(state, value) {
			state.amount = value
		},
		incrementAmount(state) {
			state.amount++
		},
		decrementAmount(state) {
			state.amount--
		},
		setLoading(state, value) {
			state.loading = value
		},
	},
	actions: {
		getBySlug({ commit }, slug) {
			commit('setLoading', true)
			return axios.get('api/products/by-slug/'+slug)
			.then(res => {
				commit('setLoading', false)
				console.log(res.data.product)
				commit('setModel', res.data.product)
			})
			.catch(err => {
				commit('setLoading', false)
				console.log(err)
			})
		}
	}
}
