export default {
	computed: {
		orders() {
			return this.$store.state.order.models
		},
		loading() {
			return this.$store.state.order.loading
		},
		products() {
			return this.$store.state.order.products
		},
		model() {
			return this.$store.state.order.model
		},
		total() {
			return this.$store.state.order.total
		},
		details() {
			return this.$store.state.order.details
		},
		display() {
			return this.$store.state.order.display
		},
		unconfirmed_orders() {
			return this.orders.filter(order => {
				return order.order_status && order.order_status.name == 'Sin confirmar'
			})
		},
		total_all_orders() {
			let total = 0
			this.orders.forEach(order => {
				// if (order.is_paid) {
					total += this.getTotalProducts(order.products, order.discount, true, false)
				// }
			})
			return total
		},
		intervalos_timbre() {
			return this.$store.state.order.intervalos_timbre
		},
		deleted_orders() {
			return this.$store.state.order.deleted_models
		},
	},
	methods: {
		getTotalProducts(products, order_discount, from_pivot = false, formated = false) {
			let total = 0
			let total_product = 0
			let price 
			let amount 
			let discount
			products.forEach(product => {
				// if (from_pivot) {
					price = product.pivot.price
					amount = product.pivot.amount
					discount = product.pivot.discount
				// } else {
				// 	price = product.price
				// 	amount = product.amount
				// 	discount = product.discount
				// }
				total_product = price * amount
				if (discount && discount != '') {
					total_product = total_product - (total_product * discount / 100)
				}
				total += total_product
			})
			if (order_discount && order_discount != '') {
				total = total - (total * order_discount / 100)
			}
			if (formated) {
				return this.price(total) 
			}
			return total 
		},
		orderItems(order) {
			let total = 0
			order.products.forEach(item => {
				total += item.pivot.amount
			})
			return total
		},
		orderDetails(order) {
			console.log('orderDetails')
			this.$store.commit('order/setDetails', order)
			this.limpiar_intervalo_de_tiembre(order)
			setTimeout(() => {
				this.$bvModal.show('order-details')
			}, 100)
		},
		limpiar_intervalo_de_tiembre(order) {
			console.log('limpiar_intervalo_de_tiembre')
			let intervalo = this.intervalos_timbre.find(intervalo => {
				return intervalo.order_id == order.id 
			})

			if (typeof intervalo != 'undefined') {
				this.$store.commit('order/addIntervaloTimbre', intervalo)

				window.clearInterval(intervalo.intervalo)
				console.log('se limpio el intervalo:')
				console.log(intervalo.intervalo)
			}
		},
		resetCreate() {
			this.$store.commit('order/setModel', {model: null})
			this.$store.commit('order/setProducts', [])
			this.$store.commit('order/setTotal')
		}
	}
}