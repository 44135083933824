export default {
	computed: {
		admin() {
			return this.$store.state.users.admin
		},
		is_admin() {
			return this.user && !this.user.owner_id
		},
		user_id() {
			return process.env.VUE_APP_USER_ID
		},
	},
}