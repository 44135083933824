<template>
	<div 
	v-if="show"
	class="cookies">
		Al navegar por este sitio aceptás el uso de cookies para agilizar tu experiencia de compra.
		<b-button
		class="m-t-10"
		block
		@click="setCookie"
		variant="primary">
			Entendido
		</b-button>
	</div>
</template>
<script>
export default {
	created() {
		console.log('can_set_cookies: '+this.order_cookie)
		this.setShow()
	},
	computed: {
		order_cookie() {
			return this.$cookies.get('can_set_cookies')
		},
	},
	data() {
		return {
			show: false
		}
	},
	methods: {
		setCookie() {
			this.$cookies.set('can_set_cookies', true, Infinity)
			console.log(this.$cookies.get('can_set_cookies'))
			this.setShow()
		},
		setShow() {
			if (!this.$cookies.get('can_set_cookies')) {
				this.show = true 
			} else {
				this.show = false
			}
		}
	}
}
</script>
<style lang="sass">
.cookies	
	background: #304357
	position: fixed
	z-index: 100
	bottom: 1em
	color: #FFF
	padding: 1em
	border-radius: 5px
	width: 80%
	left: 10%
</style>