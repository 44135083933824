export default {
	properties: [
		{
			text: 'N°',
			key: 'num',
			type: 'text',
			value: '',
			is_title: true,
			not_show_on_form: true,
			use_to_filter_in_search: true,
		},
		{
			text: 'Productos',
			key: 'products',
			type: 'search',
			store: 'product',
			belongs_to_many: {
				create_if_not_exist: true,
				props_to_show: [
					{
						text: 'Nombre',
						key: 'name',
						show: true,
					},
					{
						text: 'Precio',
						key: 'price',
						is_price: true,
						show: true,
					},
				],
				properties_to_set: [
					{
						text: 'Cantidad',
						key: 'amount',
						value: 1,
						type: 'number'
					},
					{
						text: 'Descuento',
						key: 'discount',
						value: '',
						type: 'number'
					},
					{
						text: 'Agregado',
						only_show: true,
						is_since: true,
						type: 'date',
						key: 'added_at',
						show: true,
					},
				],
				if_null: {
					prop_to_set_with_query: 'name',
				},
			}
		},
		{
			text: 'Fue modificado',
			key: 'is_modified',
			type: 'checkbox',
			value: '',
			show: true,
			only_show: true,
		},
		{
			text: 'Descripcion',
			key: 'description',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'Salon',
			key: 'salon_id',
			select_prop_name: 'nombre',
			type: 'select',
			value: 1,
			show: true,
		},
		{
			text: 'Mesa',
			key: 'mesa_id',
			type: 'select',
			select_prop_name: 'nombre',
			show: true,
			relation_prop_name: 'nombre',
		},
		// {
		// 	text: 'Mesa',
		// 	key: 'table_number',
		// 	type: 'number',
		// 	value: '',
		// 	show: true,
		// },
		{
			text: 'Mozo',
			key: 'waiter',
			type: 'text',
			value: '',
			show: true,
		},
		{
			text: 'Descuento',
			key: 'discount',
			type: 'number',
			value: '',
			show: true,
		},
		{
			text: 'Estado',
			key: 'order_status_id',
			type: 'select',
			value: 1,
			show: true,
			use_to_set_border_color: true,
		},
		{
			text: 'Metodo Pago',
			key: 'payment_method_id',
			type: 'select',
			value: 1,
			show: true,
		},
		{
			text: 'Tipo',
			key: 'order_type_id',
			type: 'select',
			value: 1,
			is_title: true,
			show: true,
		},
		{
			text: 'Mesa Libre',
			key: 'is_paid',
			type: 'checkbox',
			value: 0,
			show: true,
		},
		{
			text: 'Sonido',
			key: 'sonido',
			type: 'checkbox',
			value: 1,
		},
		{
			text: 'Total',
			key: 'total',
			type: 'number',
			only_show: true,
			function: 'totalOrder',
			show: true,
		},
		{
			text: 'Eliminado Por',
			key: 'eliminado_por',
			only_show: true,
			function: 'eliminado_por',
			show: false,
		},
		{
			text: 'Fecha de eliminacion',
			key: 'deleted_at',
			only_show: true,
			function: 'deleted_at',
			show: false,
		},
	],
	singular_model_name_spanish: 'Pedido',
	plural_model_name_spanish: 'Pedidos',
	create_model_name_spanish: 'Nuevo pedido',
	text_delete: 'el',
}