<template>
    <div id="app">
        <logo-loading></logo-loading>
        <nav-component></nav-component>
        <b-container fluid>
            <router-view/>
            <cookies></cookies>
            <footer-component></footer-component>
        </b-container> 
    </div>
</template>
<script>
import LogoLoading from '@/common-vue/components/LogoLoading'
import NavComponent from '@/components/nav/Index'
import Cookies from '@/components/common/Cookies'
import FooterComponent from '@/components/common/footer/Index'

import broadcasting from '@/mixins/broadcasting'  
import cookies from '@/mixins/cookies' 
import update_app from '@/common-vue/mixins/update_app' 
import app from '@/common-vue/mixins/app' 
export default {
    mixins: [broadcasting, cookies, update_app, app],
    components: {
        LogoLoading,
        NavComponent,
        Cookies,
        FooterComponent,
    },
    created() {
        this.redirectIfWww()
        this.$store.dispatch('auth/me')
    },
    watch: {
        async authenticated() {
            this.setView()
            this.$store.dispatch('users/getAdmin', this.user_id)
            if (this.authenticated) {
                this.listenChannels()
                this.$store.dispatch('category/getModels')
                this.$store.dispatch('product/getModels')
                this.$store.dispatch('order/getModels')
                this.$store.dispatch('payment_method/getModels')
                this.$store.dispatch('order_type/getModels')
                this.$store.dispatch('order_status/getModels')
                this.$store.dispatch('color/getModels')
                if (this.is_admin) {
                    this.$store.dispatch('employee/getModels')
                    this.$store.dispatch('permission/getModels')
                    this.$store.dispatch('order/getDeletedModels')
                }
                this.$store.dispatch('salon/getModels')
                this.$store.dispatch('mesa/getModels')
            } else {
                this.$store.commit('category/setModels', [])
                this.$store.dispatch('category/getModels', 1)
                this.setOrderFromCookies()
            }
        },
        route_name() {
            this.$scrollToTop()
        },
    },
    methods: {
        setView() {
            if (!this.authenticated && (this.route_name != 'home' && this.route_name != 'product')) {
                console.log('entro home')
                this.$router.push({name: 'home'})
            } else if (this.authenticated && (this.$route.path == '/' || this.route_name == 'login')) {
                console.log('entro order')
                if (this.can('order.store')) {
                    this.$router.push({name: 'order', params: {view: 'nuevo-pedido'}})
                } else if (this.can('order.index')) {
                    this.$router.push({name: 'order', params: {view: 'lista'}})
                }
            } else {
                console.log('no entro')
                console.log('path: '+this.$route.path)
            }
        },
    }
}
</script>
<style lang="sass">
@import '@/sass/app.sass'
</style>
