<template>
	<div>
		<p class="title">
			SEGURIDAD Y CERTIFICADOS
		</p>
        <img 
        src="@/assets/ssl.png">
	</div>
</template>
<script>
import SocialLinks from '@/components/nav/SocialLinks'
export default {
	components: {
		SocialLinks
	}
}
</script>
<style lang="sass">
.footer
	img 
		width: 100px
</style>