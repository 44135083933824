export default {
	properties: [
		{
			text: 'Imagen',
			key: 'image_url',
			type: 'image',
			value: '',
			show: true,
		},
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			required: true,
			show: true,
		},
		{
			text: 'Descripcion',
			key: 'description',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'Disponible en la tienda',
			key: 'online_available',
			type: 'checkbox',
			value: 1,
			unchecked_value: 0,
			show: true,
		},
	],
	singular_model_name_spanish: 'Categoria',
	plural_model_name_spanish: 'Categorias',
	create_model_name_spanish: 'Nueva categoria',
	text_delete: 'la',
}